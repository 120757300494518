<template>
  <div class="">
    <div class="time">报告时间:{{ dateTime }}</div>
    <div class="text1">趋势跟踪</div>
    <div class="text2">策略ID: {{ form.qsgz_id }}</div>
    <div class="name">品种：{{ categoryName }}{{ form.contract }}</div>
    <div class="data">
      <div class="data_cell">
        <div>{{ form.qujian1 }}-{{ form.qujian2 }}</div>
        <div>标准支撑/压力</div>
      </div>
      <div class="data_cell">
        <div v-if="form.qujian_type == 1" style="color: #ff2424">多</div>
        <div v-if="form.qujian_type == 2" style="color: #13bc5c">空</div>
        <div class="no_data" v-if="form.qujian_type == ''">-</div>
        <div>方向</div>
      </div>
      <div class="data_cell">
        <div>{{ form.mubiao1 }}-{{ form.mubiao1 }}</div>
        <div>目标位置</div>
      </div>
      <div class="data_cell">
        <div>{{ form.zhisun1 }}-{{ form.zhisun2 }}</div>
        <div>止损位置</div>
      </div>
      <div class="data_cell">
        <div>
          {{ form.cangwei || "-" }}
        </div>
        <div>仓位控制</div>
      </div>
      <div class="data_cell1">备注：{{ form.remark }}</div>
    </div>
    <div class="teacher_info">
      <div class="mr10">产品制作人：{{ form.teacher_name }}</div>
      <div>交易咨询证书编号：{{ form.teacher_number }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categoryName: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    formType: {
      type: Number,
      default: 0,
    },
    form: {
      type: Object,
      default: Object,
    },
    form1: {
      type: Object,
      default: Object,
    },
  },
  data() {},
};
</script>

<style scoped>
.time {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  position: absolute;
  left: 30px;
  top: 104px;
}

.name {
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
  width: 100%;
  position: absolute;
  left: 0;
  top: 225px;
  text-align: center;
}

.text1 {
  position: absolute;
  top: 157px;
  left: 0;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #000000;
}

.text2 {
  position: absolute;
  top: 191px;
  left: 0;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  color: #d70b22;
}

.teacher_info {
  position: absolute;
  left: 41px;
  bottom: 251px;
  width: 293px;
  height: 29px;
  border-radius: 20px;
  border: 1px solid #d70b22;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 12px;
  color: #d70b22;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data {
  position: absolute;
  top: 262px;
  left: 13px;
  width: calc(100% - 26px);
  display: flex;
  flex-wrap: wrap;
  row-gap: 23px;
}

.data_cell {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.data_cell > div:first-child {
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  margin-bottom: 4px;
}

.data_cell > div:last-child {
  font-weight: 400;
  font-size: 15px;
  color: #666666;
}

.data_cell1 {
  width: calc(100% - 14px);
  font-weight: 400;
  font-size: 13px;
  color: #333333;
  box-sizing: border-box;
  margin: 0 28px;
  padding-top: 10px;
  word-break: break-all;
  border-top: 1px solid #eeeeee;
}
</style>
