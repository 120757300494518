<template>
  <div>
    <pageTitle title="趋势跟踪列表"></pageTitle>
    <div class="bb p30">
      <div class="head flex fw_w">
        <div>
          <div>策略ID</div>
          <div class="w200">
            <el-input
              clearable
              size="large"
              v-model="qsgz_id"
              placeholder="请输入策略ID"
            ></el-input>
          </div>
        </div>
        <div>
          <div>品种</div>
          <div>
            <el-select
              v-model="category_id"
              class="m-2"
              placeholder="请选择品种"
              size="large"
            >
              <el-option label="全部" value="" />
              <el-option
                v-for="(item, index) in categoryList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <div>
          <div>产品制作人</div>
          <div>
            <el-select
              v-model="teacher_name"
              class="m-2"
              placeholder="请选择产品制作人"
              size="large"
            >
              <el-option label="全部" value="" />
              <el-option
                v-for="(item, index) in teacherList"
                :key="index"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </div>
        </div>
        <div>
          <div>发布时间</div>
          <div>
            <el-date-picker
              v-model="time_range"
              type="date"
              placeholder="请选择发布时间"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              size="large"
            />
          </div>
        </div>
        <!-- <div>
          <div>是否平仓</div>
          <div>
            <el-select v-model="type" class="m-2" placeholder="" size="large">
              <el-option label="全部" :value="0" />
              <el-option label="未平仓" :value="1" />
              <el-option label="已平仓" :value="2" />
            </el-select>
          </div>
        </div>
        <div>
          <div>状态</div>
          <div>
            <el-select v-model="status" class="m-2" placeholder="" size="large">
              <el-option label="全部" :value="0" />
              <el-option label="止盈平仓" :value="1" />
              <el-option label="止损平仓" :value="2" />
            </el-select>
          </div>
        </div> -->
        <div>
          <el-button @click="getTrendFollowingList(1)" type="primary"
            >搜 索</el-button
          >
        </div>
      </div>
      <div v-loading="loading">
        <div class="mb20">
          <el-table
            v-loading="loadingTable"
            :data="trackList"
            height="70vh"
            style="width: 100%"
          >
            <el-table-column
              width="80"
              align="center"
              prop="qsgz_id"
              label="策略ID"
            />
            <el-table-column width="150" align="center" label="图片">
              <template #default="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.image"
                  :preview-src-list="[scope.row.image]"
                  fit="cover"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column width="300" align="center" label="品种合约">
              <template #default="scope">
                {{ scope.row.category.name }}
              </template>
            </el-table-column>
            <el-table-column width="200" align="left" label="方向">
              <template #default="scope">
                <div v-if="scope.row.qujian_type == 1" style="color: #ff2424">
                  多
                </div>
                <div v-if="scope.row.qujian_type == 2" style="color: #13bc5c">
                  空
                </div>
              </template>
            </el-table-column>
            <el-table-column width="200" align="left" label="标准支撑/压力">
              <template #default="scope">
                {{ scope.row.qujian1 }}~{{ scope.row.qujian2 }}
              </template>
            </el-table-column>
            <el-table-column width="200" align="left" label="目标位置">
              <template #default="scope">
                {{ scope.row.mubiao1 }}~{{ scope.row.mubiao2 }}
              </template>
            </el-table-column>
            <el-table-column width="200" align="left" label="止损位置">
              <template #default="scope">
                {{ scope.row.zhisun1 }}~{{ scope.row.zhisun2 }}
              </template>
            </el-table-column>
            <el-table-column width="200" align="center" label="仓位控制">
              <template #default="scope">
                {{ scope.row.cangwei || "-" }}
              </template>
            </el-table-column>
            <el-table-column width="200" align="center" label="备注">
              <template #default="scope">
                {{ scope.row.remark || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              width="150"
              align="center"
              prop="teacher_name"
              label="产品制作人"
            >
            </el-table-column>
            <el-table-column
              width="170"
              align="center"
              prop="createtime_text"
              label="发布时间"
            />
          </el-table>
        </div>
        <div>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            v-model:page-size="pageSize"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            :page-sizes="[15, 50, 100, 200]"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageTitle from "@/components/pageTitle.vue";
import * as Service from "@/api/companyCircle";
import * as ServiceB from "@/api/bbas";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      qsgz_id: null,
      teacher_name: "",
      time_range: "", // 发布时间范围
      type: "",
      status: "",
      teacherList: [], //研究员列表
      page: 1,
      pageSize: 15,
      trackList: [],
      loading: false,
      categoryList: [],
      category_id: null,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getBaseInfo1();
    this.getBaseInfo2();
    this.getTrendFollowingList(1);
  },
  methods: {
    onmessage(e) {},
    // 获取基础信息
    async getBaseInfo2() {
      const res = await ServiceB.BaseInfo();
      this.categoryList = res.data.categoryList;
    },
    // 获取基础信息
    async getBaseInfo1() {
      const res = await Service.getBaseInfo();
      this.teacherList = res.data.teacherList;
    },
    // 获取趋势跟踪列表
    async getTrendFollowingList(page) {
      this.loading = true;
      // var time_range0 = this.time_range ? [...this.time_range] : [];
      // if (time_range0.length) {
      //   time_range0[0] = time_range0[0] / 1000;
      //   time_range0[1] = time_range0[1] / 1000;
      // }
      const res = await Service.getTrendFollowingList({
        page,
        qsgz_id: this.qsgz_id,
        category_id: this.category_id,
        teacher_name: this.teacher_name,
        time_range: this.time_range,
        limit: this.pageSize,
      });
      this.page = page;
      this.total = res.data.total;
      this.trackList = res.data.data;
      this.loading = false;
    },
    handleCurrentChange(val) {
      this.getTrendFollowingList(val);
      console.log(`当前页: ${val}`);
    },
    handleSizeChange(val) {
      this.getTrendFollowingList(1);
    },
  },
};
</script>

<style scoped>
.el-table ::v-deep th.el-table__cell,
.el-table ::v-deep td.el-table__cell {
  position: static;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w200 {
  width: 200px;
}

.teacher_remark {
  max-width: 300px;
  display: inherit !important;
}
</style>
