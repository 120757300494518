<template>
  <div class="flex" @click="privateEmojiStatus = false">
    <div class="side_left">
      <div style="display: flex; height: calc(100% - 450px); overflow-x: auto">
        <!-- 表单 -->
        <div class="w100">
          <div class="plate_title">
            <div class="plate_title_name">短线跟踪</div>
          </div>

          <div class="form_body">
            <!-- 开仓信息 -->
            <div class="form" v-loading="loading">
              <el-form
                :size="sizeVal"
                label-position="top"
                class="demo-form-inline"
              >
                <div class="flex at_c jc_b">
                  <el-form-item label="日期" style="width: calc(50% - 20px)">
                    <el-date-picker
                      style="width: 100%"
                      @change="changeTime"
                      v-model="form.time"
                      type="date"
                      placeholder="选择日期"
                      value-format="YYYY-MM-DD"
                      format="YYYY-MM-DD"
                    />
                  </el-form-item>
                </div>

                <div class="flex at_c jc_b">
                  <el-form-item
                    label="品种"
                    style="width: calc((100% - 40px) / 2)"
                  >
                    <el-select
                      style="width: 100%; max-width: 300px"
                      popper-class="w100"
                      filterable
                      v-model="form.category_id"
                      placeholder="请选择品种"
                      @change="changeCategory"
                    >
                      <el-option
                        v-for="(item, index) in categoryList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item
                    label="合约"
                    style="width: calc((100% - 40px) / 2)"
                  >
                    <el-input
                      v-model="form.contract"
                      placeholder="请输入合约"
                    ></el-input>
                  </el-form-item>
                </div>

                <div class="flex at_c jc_b">
                  <el-form-item
                    label="标准支撑/压力"
                    style="width: calc(50% - 20px)"
                  >
                    <div class="flex at_c">
                      <el-input
                        class="w_input_px"
                        v-model="form.qujian1"
                        placeholder="支撑"
                      />
                      <el-divider></el-divider>
                      <el-input
                        class="w_input_px"
                        v-model="form.qujian2"
                        placeholder="压力"
                      />
                    </div>
                  </el-form-item>
                  <el-form-item label="方向" style="width: calc(50% - 20px)">
                    <el-radio-group v-model="form.qujian_type">
                      <el-radio-button label="1">多</el-radio-button>
                      <el-radio-button label="2">空</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                </div>

                <div class="flex at_c jc_b">
                  <el-form-item
                    label="目标位置"
                    style="width: calc(50% - 20px)"
                  >
                    <div class="flex at_c">
                      <el-input
                        class="w_input_px"
                        v-model="form.mubiao1"
                        placeholder="最小值"
                      />
                      <el-divider></el-divider>
                      <el-input
                        class="w_input_px"
                        v-model="form.mubiao2"
                        placeholder="最大值"
                      />
                    </div>
                  </el-form-item>

                  <el-form-item
                    label="止损位置"
                    style="width: calc(50% - 20px)"
                  >
                    <div class="flex at_c">
                      <el-input
                        class="w_input_px"
                        v-model="form.zhisun1"
                        placeholder="支撑"
                      />
                      <el-divider></el-divider>
                      <el-input
                        class="w_input_px"
                        v-model="form.zhisun2"
                        placeholder="压力"
                      />
                    </div>
                  </el-form-item>
                </div>

                <el-form-item label="仓位控制" style="width: 100%">
                  <el-input
                    type="text"
                    v-model="form.cangwei"
                    placeholder=""
                  ></el-input>
                </el-form-item>

                <el-form-item label="备注" style="width: 100%">
                  <el-input
                    type="text"
                    v-model="form.remark"
                    placeholder=""
                  ></el-input>
                </el-form-item>

                <el-form-item label="产品制作人" style="width: 100%">
                  <el-select
                    style="width: 100%; max-width: 300px"
                    popper-class="w100"
                    filterable
                    v-model="form.teacher_number"
                    placeholder="请选择产品制作人"
                    @change="changeNumber"
                  >
                    <el-option
                      v-for="(item, index) in teacherList"
                      :key="index"
                      :label="item.name"
                      :value="item.number"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item>
                  <el-button
                    class="mt32"
                    type="primary"
                    :loading="loadingForm"
                    @click="updataCelueId"
                    >立即发布</el-button
                  >
                  <el-button class="mt32" @click="resetFormData"
                    >重置</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 450px">
        <!-- 直播间公屏 -->
        <div class="live_room_body">
          <div class="plate_title">
            <div class="plate_title_name">
              <div>直播间公屏</div>
            </div>
            <el-button @click="sendLiveTips" type="primary" size="small"
              >发送开播提醒</el-button
            >
          </div>
          <div class="live_message_lists" id="privateChat2">
            <div
              class="live_message_cell"
              v-for="(item, index) in chatList"
              :key="index"
            >
              <div class="flex at_c jc_b">
                <div class="name hang1">
                  {{ item.name }}
                </div>
                <div class="time">{{ item.createtime_text }}</div>
              </div>
              <div class="content" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 预览图 -->
    <div class="preview_table">
      <div class="plate_title">
        <div class="plate_title_name1">
          <img class="mr15" src="@/assets/img/icon_preview.png" alt="" />
          <div>短线跟踪预览</div>
        </div>
      </div>
      <div class="preview_body">
        <div class="publicity_map" id="capture">
          <strategyCard6
            ref="strategyCard6"
            :category-name="category_name"
            :date-time="date_time"
            :form-type="formType"
            :form="form"
            :disclaimer="disclaimer"
          ></strategyCard6>
        </div>

        <div style="width: 100%; height: 20px"></div>
      </div>
    </div>
    <!-- 聊天 -->
    <div
      class="private_chat"
      v-loading="messageListLoading"
      v-show="chatBoxStatus"
    >
      <div class="private_chat_head">
        <div>
          <span
            >{{ currentTalkMember.nickname }}
            <!-- <span v-if="chat_type == 4">({{ userTotal }})</span> -->
          </span>
          <span v-if="chat_type == 4 && circleStatus == 0" class="estoppel"
            >已禁言</span
          >
          <span>{{
            memberInfo.keywords ? "关键词：" + memberInfo.keywords : ""
          }}</span>
        </div>
        <div class="flex at_c" v-if="chat_type == 4">
          <div class="flex at_c mr30" v-if="!is_company">
            <div>当前圈播状态：</div>
            <div
              class="plate_push_btn"
              :class="{ plate_push_btn_active: live_status == 1 }"
              @click="setLiveStatus(1)"
            >
              <img
                v-if="live_status == 1"
                class="icon_push_speak"
                src="@/assets/img/icon_push_speaks.png"
                alt=""
              />
              <img
                v-else
                class="icon_push_speak"
                src="@/assets/img/icon_push_speak.png"
                alt=""
              />
              开播
            </div>
            <div
              class="plate_push_btn"
              :class="{ plate_push_btn_active: live_status == 0 }"
              @click="setLiveStatus(0)"
            >
              <img
                v-if="live_status == 0"
                class="icon_push_stop"
                src="@/assets/img/icon_push_stops.png"
                alt=""
              />
              <img
                v-else
                class="icon_push_stop"
                src="@/assets/img/icon_push_stop.png"
                alt=""
              />
              停播
            </div>
          </div>
          <div
            v-if="!is_company"
            class="video_url_cell"
            @click="copyText(play_url)"
          >
            <img
              title="播放地址"
              class="icon_shezhi"
              src="@/assets/img/icon_lianjie.png"
              alt=""
            />播放地址
          </div>
          <div
            v-if="!is_company"
            class="video_url_cell"
            @click="copyText(push_url)"
          >
            <img
              title="推流地址"
              class="icon_shezhi"
              src="@/assets/img/icon_lianjie.png"
              alt=""
            />推流地址
          </div>
          <!-- <img
            title="圈子信息"
            @click="showDrawer"
            class="icon_shezhi"
            src="@/assets/img/icon_shezhi.png"
            alt=""
          /> -->
        </div>
      </div>
      <div class="private_chat_body">
        <el-drawer
          v-model="drawer"
          :direction="direction"
          :size="377"
          v-loading="circleUserListLoading"
        >
          <template #title>
            <div>
              <div class="flex at_c">
                <h4>圈子成员</h4>
                <div class="ml10" v-if="circleIsEnd">已全部加载</div>
                <div v-if="circleUserListLoading">
                  <img
                    class="loading"
                    src="@/assets/img/loading.gif"
                    alt=""
                  />加载中...
                </div>
              </div>
              <div class="flex at_c">
                <div class="mr10">在线状态</div>
                <div>
                  <el-radio-group v-model="is_online">
                    <el-radio-button label="全部" />
                    <el-radio-button label="在线" />
                  </el-radio-group>
                </div>
                <div class="ml10">人数：{{ circleUserTotal }}</div>
              </div>
            </div>
          </template>
          <template #default>
            <div
              class="circle_users"
              v-infinite-scroll="circleLoad"
              :infinite-scroll-delay="1000"
              :infinite-scroll-immediate="false"
            >
              <div
                class="circle_user_cell"
                v-for="(item, index) in circleUserList"
                :key="index"
              >
                <el-popconfirm
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  icon-color="#626AEF"
                  :title="
                    item.status == 0
                      ? '确认解除禁言该用户吗?'
                      : '确认禁言该用户吗?'
                  "
                  @confirm="
                    changeCircleUserChatStatus(item.user_id, item.status, index)
                  "
                >
                  <template #reference>
                    <div>
                      <div
                        class="pr can bb"
                        :class="{ biankuang: item.user.is_firm_offer }"
                      >
                        <img class="avatar" :src="item.user.avatar" alt="" />
                        <img
                          v-if="item.status == 0"
                          class="icon_jinyan"
                          src="@/assets/img/icon_jinyan.png"
                          alt=""
                        />
                        <div
                          class="firm_offer_name"
                          v-if="item.user.firm_offer_name"
                        >
                          {{ item.user.firm_offer_name }}
                        </div>
                        <!-- <div
                          class="is_firm_offer"
                          v-if="item.user.is_firm_offer"
                        >
                          <div>实盘</div>
                        </div> -->
                        <!-- 用户在线状态 -->
                        <div class="online_status1" v-if="item.is_online == 1">
                          <div class="staff_status_online"></div>
                          在线
                        </div>
                        <div class="online_status0" v-else>
                          <div
                            class="staff_status_online staff_status_offline"
                          ></div>
                          离线
                        </div>
                      </div>
                      <div class="hang1 tac">{{ item.user.nickname }}</div>
                    </div>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </template>
          <template #footer>
            <div class="w100">
              <el-button @click="changeCircleChatStatus" class="w100">{{
                circleStatus == 1 ? "全员禁言" : "解除禁言"
              }}</el-button>
            </div>
          </template>
        </el-drawer>

        <div
          class="private_chat_left"
          :class="{ max_width: !chatBoxRightStatus }"
        >
          <div class="pr" style="height: calc(100% - 340px)">
            <div class="private_chat_list" id="privateChat">
              <div class="is_end" v-if="isEndMessage">已无更多消息</div>
              <div v-for="(item, index) in messageList" :key="index">
                <!-- 讲师说话右侧 -->
                <div v-if="item.direction == 1" class="flex fd_c at_e">
                  <div class="flex at_c fz12" style="color: #858fa2">
                    <div class="mr6">{{ item.name }}</div>
                    <div>{{ item.time }}</div>
                  </div>
                  <el-popconfirm
                    v-if="chat_type == 4"
                    confirm-button-text="确认"
                    cancel-button-text="取消"
                    icon-color="#626AEF"
                    title="确认删除该发言吗?"
                    @confirm="delConfirm(item.id)"
                  >
                    <template #reference>
                      <div
                        class="cori_chat_box_list_cell_content can"
                        style="background-color: #347eff; color: #fff"
                      >
                        <div
                          class="pr"
                          v-if="item.message_type != 'disclaimer'"
                        >
                          <!-- 引用的内容 -->
                          <div
                            v-if="item.reply_info.id"
                            class="reply_content"
                            style="color: rgba(255, 255, 255, 0.6)"
                          >
                            <div class="reply_content_name">
                              {{ item.reply_info.name }}：
                            </div>
                            <div
                              class="reply_content_content"
                              v-html="item.reply_info.content"
                            />
                          </div>
                          <div v-html="item.content"></div>
                          <!-- 更多操作 -->
                          <div class="more_handles" style="left: -40px">
                            <img
                              class="icon_message"
                              src="@/assets/img/icon_message.png"
                              title="回复"
                              alt=""
                              @click.stop="chooseReply(item)"
                            />
                          </div>
                        </div>
                        <div v-else>
                          <div
                            :class="{
                              disclaimer_content: !item.message_type_show,
                            }"
                            v-html="item.content"
                          ></div>
                          <div
                            class="w100 flex jc_c"
                            v-if="!item.message_type_show"
                          >
                            <div
                              class="disclaimer_viewing_all can"
                              @click.stop="showMessageTypeShow(index)"
                            >
                              查看全部
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-popconfirm>
                  <div
                    v-if="chat_type == 2"
                    class="cori_chat_box_list_cell_content"
                    style="background-color: #347eff; color: #fff"
                    v-html="item.content"
                  ></div>
                  <div
                    class="topic_about"
                    v-if="item.topic_id && chat_type == 4"
                  >
                    <div class="flex at_c topic_about_cell">
                      <img
                        class="icon_topic"
                        src="@/assets/img/icon_topic.png"
                        alt=""
                      />
                      {{ item.topic_info.name }}
                    </div>
                    <div class="flex at_c">
                      已点赞{{ item.zan_num }}次
                      <img
                        class="icon_zan"
                        src="@/assets/img/icon_zan.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <!-- 来访者说话左侧 -->
                <div v-if="item.direction == 2" class="cori_chat_box_list_cell">
                  <el-dropdown v-if="chat_type == 4" @command="handleCommand">
                    <img
                      class="cori_chat_box_list_cell_avatar can"
                      :src="item.avatar"
                    />
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          :command="beforeHandleCommand('a', item.user_id)"
                          >查看资料</el-dropdown-item
                        >
                        <el-dropdown-item
                          :command="beforeHandleCommand('b', item.user_id)"
                          >禁言</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                  <!-- <el-popconfirm
                  v-if="chat_type == 4"
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  icon-color="#626AEF"
                  title="确认禁言该用户吗?"
                  @confirm="banConfirm(item.user_id)"
                >
                  <template #reference>
                    <img
                      class="cori_chat_box_list_cell_avatar can"
                      :src="item.avatar"
                    />
                  </template>
                </el-popconfirm> -->
                  <img
                    v-if="chat_type == 2"
                    class="cori_chat_box_list_cell_avatar"
                    :src="item.avatar"
                  />
                  <div class="cori_chat_box_list_cell_info">
                    <div class="flex at_c fz12" style="color: #858fa2">
                      <div class="mr6">{{ item.name }}</div>
                      <div>{{ item.time }}</div>
                    </div>
                    <el-popconfirm
                      v-if="chat_type == 4"
                      confirm-button-text="确认"
                      cancel-button-text="取消"
                      icon-color="#626AEF"
                      title="确认删除该发言吗?"
                      @confirm="delConfirm(item.id)"
                    >
                      <template #reference>
                        <div
                          :class="{ dummy_text: item.is_dummy }"
                          class="cori_chat_box_list_cell_content can"
                        >
                          <div
                            class="pr"
                            v-if="item.message_type != 'disclaimer'"
                          >
                            <!-- 引用的内容 -->
                            <div
                              v-if="item.reply_info.id"
                              class="reply_content"
                            >
                              <div class="reply_content_name">
                                {{ item.reply_info.name }}：
                              </div>
                              <div
                                class="reply_content_content"
                                v-html="item.reply_info.content"
                              />
                            </div>
                            <div v-html="item.content"></div>
                            <!-- 更多操作 -->
                            <div class="more_handles jc_e" style="right: -40px">
                              <img
                                class="icon_message"
                                src="@/assets/img/icon_message.png"
                                title="回复"
                                alt=""
                                @click.stop="chooseReply(item)"
                              />
                            </div>
                          </div>
                          <div v-else>
                            <div
                              :class="{
                                disclaimer_content: !item.message_type_show,
                              }"
                              v-html="item.content"
                            ></div>
                            <div
                              class="w100 flex jc_c"
                              v-if="!item.message_type_show"
                            >
                              <div
                                class="disclaimer_viewing_all can"
                                style="border-color: #000; color: #000"
                                @click.stop="showMessageTypeShow(index)"
                              >
                                查看全部
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-popconfirm>
                    <div
                      v-if="chat_type == 2"
                      class="cori_chat_box_list_cell_content"
                      v-html="item.content"
                    ></div>
                  </div>
                </div>

                <!-- 用户信息弹窗 -->
                <!-- <el-dialog
                  v-model="dialogVisible"
                  title="用户信息"
                  width="500px"
                  :modal="false"
                  :show-close="false"
                  custom-class="user_info_dialog"
                >
                  <div class="customer_info">
                    <div class="customer_cell">
                      <div>昵称</div>
                      <div>{{ information.nickname }}</div>
                    </div>
                    <div class="customer_cell">
                      <div>IP</div>
                      <div>{{ information.ip }}</div>
                    </div>
                    <div class="customer_cell">
                      <div>着陆页</div>
                      <div>{{ information.url }}</div>
                    </div>
                    <div class="customer_cell">
                      <div>省市</div>
                      <div>
                        {{ information.province }}{{ information.city
                        }}{{ information.county }}
                      </div>
                    </div>
                    <div class="customer_cell">
                      <div>运营商</div>
                      <div>{{ information.lsp }}</div>
                    </div>
                    <div class="customer_cell">
                      <div>关键词</div>
                      <div>{{ information.keywords }}</div>
                    </div>
                    <div class="customer_cell">
                      <div>用户组</div>
                      <div>{{ information.type }}</div>
                    </div>
                    <div class="customer_cell">
                      <div>观看视频</div>
                      <div>
                        {{ information.video_status == 1 ? "正常" : "封禁" }}
                      </div>
                    </div>
                    <div class="customer_cell">
                      <div>发言</div>
                      <div>
                        {{ information.chat_status == 1 ? "正常" : "封禁" }}
                      </div>
                    </div>
                    <div class="customer_cell">
                      <div>归属</div>
                      <div>{{ information.service_name }}</div>
                    </div>
                    <div class="cori_divider cori_divider_small">
                      <div>实盘数据</div>
                    </div>
                    <div class="customer_cell">
                      <div>姓名</div>
                      <div>{{ information.offerInfo.info.name }}</div>
                    </div>
                    <div class="customer_cell">
                      <div>手机号</div>
                      <div>{{ information.offerInfo.info.phone }}</div>
                    </div>

                    <div class="customer_cell">
                      <div>期末权益</div>
                      <div>
                        <div
                          class="mb15"
                          v-for="(item, index) in information.offerInfo.funds"
                          :key="index"
                        >
                          <div class="mb5">资产账号：{{ item.asset }}</div>
                          <div class="mb5">开户时间：{{ item.opentime }}</div>
                          <div class="mb5">天数：{{ item.dormancy_days }}</div>
                          <div>权益：{{ item.last_money }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button type="primary" @click="dialogVisible = false"
                        >确认</el-button
                      >
                    </span>
                  </template>
                </el-dialog> -->
              </div>
            </div>
            <!-- 引用回复 -->
            <div v-if="reply_info.id" class="quote_body">
              <div class="content">
                <div>{{ reply_info.name }}：</div>
                <div v-html="reply_info.content" />
              </div>
              <img
                class="icon_close3"
                src="@/assets/img/icon_close3.png"
                alt=""
                @click="closeReply"
              />
            </div>
          </div>
          <div class="private_chat_bottom">
            <div class="private_chat_bottom_more pr">
              <div>
                <emoji
                  :id="emojiId"
                  v-if="privateEmojiStatus"
                  @changeTextarea="changeTextarea"
                ></emoji>
                <img
                  @click.stop="changeEmojiStatus('private')"
                  class="private_chat_bottom_more_icon"
                  src="@/assets/img/icon_emoji.png"
                  alt=""
                />
              </div>
              <div class="flex at_c">
                <!-- <div class="switch_identity">
                  <img src="@/assets/img/icon_gz.png" alt="" />
                  切换身份
                </div> -->
                <div class="flex at_c" v-if="chat_type == 4">
                  <div class="mr10">
                    <el-select
                      @change="dummy_id = null"
                      v-model="topic_id"
                      placeholder=""
                      clearable
                    >
                      <el-option
                        class="flex at_c"
                        v-for="item in topicList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </el-option>
                    </el-select>
                  </div>
                  <div>
                    <el-select
                      v-if="!is_company"
                      @change="topic_id = null"
                      v-model="dummy_id"
                      placeholder=""
                      clearable
                    >
                      <el-option
                        class="flex at_c"
                        v-for="item in dummyList"
                        :key="item.id"
                        :label="item.nickname"
                        :value="item.id"
                      >
                        <img
                          class="switch_identity_avatar"
                          :src="item.avatar"
                          alt=""
                        />
                        {{ item.nickname }}
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="private_chat_input_body">
              <div id="editor" @keydown.enter="keyDown"></div>
              <div class="flex at_c jc_e mt10">
                <div class="mr10 fz14" style="color: #979797">
                  Enter 发送，Ctrl+Enter 换行
                </div>
                <div @click="privateChatSend" class="chat_btn">发送</div>
              </div>
            </div>
          </div>
        </div>
        <div class="private_chat_right" v-if="chatBoxRightStatus">
          <div class="private_chat_right_title">
            <img src="@/assets/img/icon_history.png" alt="" />
            历史轨迹
          </div>
          <div class="fz14">
            <div class="bb p10 border-b">
              首次访问 {{ memberInfo.first_time }}
            </div>
            <div class="bb p10 border-b">
              本次访问 {{ memberInfo.last_time }}
            </div>
            <div class="bb p10 border-b">
              本月访问次数 {{ memberInfo.visit_count }}次
            </div>
          </div>
          <div class="private_chat_right_title">
            <img src="@/assets/img/icon_infor.png" alt="" />
            客户信息
          </div>
          <div class="customer_info overflow_y">
            <div class="customer_cell">
              <div>昵称</div>
              <div>{{ memberInfo.nickname }}</div>
            </div>
            <div class="customer_cell">
              <div>IP</div>
              <div>{{ memberInfo.ip }}</div>
            </div>
            <div class="customer_cell">
              <div>着陆页</div>
              <div>{{ memberInfo.url }}</div>
            </div>
            <div class="customer_cell">
              <div>省市</div>
              <div>
                {{ memberInfo.province }}{{ memberInfo.city
                }}{{ memberInfo.county }}
              </div>
            </div>
            <div class="customer_cell">
              <div>运营商</div>
              <div>{{ memberInfo.lsp }}</div>
            </div>
            <div class="customer_cell">
              <div>关键词</div>
              <div>{{ memberInfo.keywords }}</div>
            </div>
            <div class="customer_cell">
              <div>用户组</div>
              <div>{{ memberInfo.type }}</div>
            </div>
            <div class="customer_cell">
              <div>观看视频</div>
              <div>{{ memberInfo.video_status == 1 ? "正常" : "封禁" }}</div>
            </div>
            <div class="customer_cell">
              <div>发言</div>
              <div>{{ memberInfo.chat_status == 1 ? "正常" : "封禁" }}</div>
            </div>
            <div class="customer_cell">
              <div>归属</div>
              <div>{{ memberInfo.service_name }}</div>
            </div>
            <div class="cori_divider cori_divider_small">
              <div>实盘数据</div>
            </div>
            <div class="customer_cell">
              <div>姓名</div>
              <div>{{ memberInfo.offerInfo.info.name }}</div>
            </div>
            <div class="customer_cell">
              <div>手机号</div>
              <div>{{ memberInfo.offerInfo.info.phone }}</div>
            </div>

            <div class="customer_cell">
              <div>期末权益</div>
              <div>
                <div
                  class="mb15"
                  v-for="(item, index) in memberInfo.offerInfo.funds"
                  :key="index"
                >
                  <div class="mb5">资产账号：{{ item.asset }}</div>
                  <div class="mb5">开户时间：{{ item.opentime }}</div>
                  <div class="mb5">天数：{{ item.dormancy_days }}</div>
                  <div>权益：{{ item.last_money }}</div>
                </div>
              </div>
            </div>
            <!-- <div class="cori_divider cori_divider_small">
              <div>实盘数据</div>
            </div>
            <div class="customer_cell">
              <div>姓名</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>休眠天数</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>期末权益</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>开户时间</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>出金</div>
              <div>{{memberInfo.nickname}}</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageTitle from "@/components/pageTitle.vue";
import strategyCard6 from "@/components/strategyCard6.vue";
import * as Service from "@/api/companyCircle";
import * as ServiceB from "@/api/bbas";
import * as ServiceChat from "@/api/chat";
import Emoji from "@/components/emoji.vue";
import { emojiReplace } from "@/utils/common";
import * as ServiceC from "@/api/circleManage";
import * as ServiceIndex from "@/api/index";
import E from "wangeditor";
import html2canvas from "html2canvas";
const OSS = require("ali-oss");
export default {
  components: {
    Emoji,
    pageTitle,
    html2canvas,
    strategyCard6,
  },
  data() {
    return {
      title: "圈子",
      room_id: 1,
      loading: false, //切换时的加载状态
      size: "large",
      loadingForm: false, //发布开仓信息按钮加载状态
      loadingForm1: false, //发布平仓信息按钮加载状态
      categoryList: [], // 品种列表
      imgUrl: "",
      date_time: "", // 信息的发布时间
      category_name: null, //品种名称
      form: {
        time: "",
        dxcl_id: null,
        category_id: null,
        contract: "",
        qujian1: "",
        qujian2: "",
        qujian_type: "",
        mubiao1: "",
        mubiao2: "",
        zhisun1: "",
        zhisun2: "",
        cangwei: "",
        remark: "",
        image: "",
        teacher_name: "",
        teacher_number: "",
      },
      checked1: true,
      images_base64: "",
      technologyCaseList: [], // 短线跟踪列表
      varietiesList: [], //品种列表
      teacherList: [], //研究员列表
      laiyuan_arr: [],
      qujian_arr: [],
      zhibiao_arr: [],
      tableData: [],
      loadingTable: false,
      page: 1,
      total: null,
      pageSize: 15,

      teacher_id: 0, // 当前在麦研究员类型
      live_status: null,

      postList: [], // 海报列表
      postStatus: false, // 海报显示状态
      postIndex: null,

      userInfo: {}, // 研究员信息
      userName: "", //  当前用户名称
      userId: null, // 当前用户id
      customerStatus: 1, //1全部客户2在线客户
      customerList: [], //客户列表
      customerListloading: false, //客户列表加载状态
      isEnd: false,
      currentTalkMember: {
        id: null,
        type: null,
        nickname: "",
      }, //当前聊天对象用户信息
      messageList: [], //当前聊天消息列表
      messageListLoading: false, // 当前聊天消息列表加载状态
      privateEmojiStatus: false, // 私聊emoji输入状态
      emojiId: "", // 当前激活的emoji id
      textarea: "", // 私聊待发送内容
      memberInfo: {
        offerInfo: {
          info: {},
          funds: [],
        },
      }, // 私聊人员相关信息

      memberList: [],
      groupSendShow: false, // 多人群发弹出层
      currentPage: 1, // 多人群发用户列表当前页码
      tableLoading: false, // 多人群发数据表加载状态
      searchVal1: "", // 会员列表检索
      searchVal2: "", // 多人群发会员检索
      multipleSelection: [],
      content: "", // 多人群发待发送内容
      // groupInfo: {
      //   cover: require("@/assets/img/group_chat_cover.png"),
      //   name: "我的实盘",
      //   is_new: false,
      //   is_new_message: false,
      //   createtime: "",
      // },
      circleInfo: {
        cover: require("@/assets/img/group_chat_cover.png"),
        name: "我的圈子",
        is_new: false,
        is_new_message: false,
        createtime: "",
      },
      chatBoxStatus: false,
      chatBoxRightStatus: true,
      chat_type: null, //当前聊天模式1群聊2私聊4圈子
      userData: [],
      dummy_id: null, // 假人id
      dummyList: [],
      drawer: false,
      direction: "rtl",
      circleUserList: [], // 圈子人员列表
      circleUserListLoading: false, // 圈子人员加载状态
      circleStatus: 1, // 0 禁言 1 发言
      userTotal: 0, // 当前圈子总人数
      circleUserPage: 1,
      circleIsEnd: false,
      limit: 50,
      circleList: [], // 圈子列表

      editor: null,
      editor1: null,
      topic_id: null, // 话题id
      isEndMessage: false, // 历史消息是否查看到头

      informationStatus: true, // 圈子用户信息
      information: {},
      dialogVisible: false,

      isAddAllStatus: false,

      play_url: "", // 播放地址
      push_url: "", // 推流地址

      is_online: "全部", // 0全部1在线
      circleUserTotal: 0, // 圈子人员列表总人数

      is_company: 0, // 是否是期货公司账号

      audio: null,

      timer: null,

      reply_info: {
        id: null,
        name: "",
        content: "",
      }, // 引用回复的内容

      sizeVal: "",
      disclaimer: "", // 免责条款

      chatList: [], // 直播间公屏
    };
  },
  beforeUnmount() {
    console.error("销毁editor-beforeUnmount");
    this.editor.destroy();
    clearInterval(this.timer);
    this.timer = null;
  },
  watch: {
    is_online() {
      this.circleUserList = [];
      this.circleUserPage = 1;
      this.getCircleUserList(1);
    },
  },
  created() {},
  mounted() {
    let that = this;
    window.onmessageSocket = this.onmessage;
    this.getBaseInfo1();
    this.getBaseInfo2();
    this.getTechnologyCaseList(1);

    if (this.customerStatus == 1) {
      this.getCustomerList1(1);
    } else {
      this.getCustomerList2();
    }

    this.showRealTime();
    this.timer = setInterval(() => {
      this.showRealTime();
    }, 1000 * 10);

    this.getUserData();
    this.getTeacherInfo();
    this.getDummyList();
    this.getCircleList();
    this.getTopicList();

    if (!this.editor) {
      this.createEditor();
    }

    $("#privateChat").scroll(function () {
      var top = $("#privateChat").scrollTop();
      if (top == 0) {
        that.getHistoryChatList();
      }
    });

    this.is_company = localStorage.getItem("is_company");

    this.$alert("允许网站播放声音", "提示", {
      confirmButtonText: "确定",
      callback: (action) => {
        this.createAudio();
      },
    });

    // 创建粘贴事件
    document.addEventListener("paste", function (e) {
      if (!(e.clipboardData && e.clipboardData.items)) {
        return;
      }
      for (var i = 0, len = e.clipboardData.items.length; i < len; i++) {
        var item = e.clipboardData.items[i];

        if (item.kind === "string") {
          item.getAsString(function (str) {
            console.log("str", str);
            // str 是获取到的字符串
          });
        } else if (item.kind === "file") {
          var pasteFile = item.getAsFile();
          console.log("pasteFile", pasteFile);

          let client = new OSS({
            // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
            region: "oss-cn-shanghai",
            // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
            accessKeyId: "LTAI5tRozUZ3WynSFfTzC72S",
            accessKeySecret: "HGfPDLd6zylQVVU7c9tkQT7uGKpRd7",
            bucket: "i-vce",
          });
          var name = that.getFileName(pasteFile);
          client
            .put(name, pasteFile)
            .then(function (res) {
              // 上传图片，返回结果，将图片插入到编辑器中
              console.log("res", res);
              that.form.zhibiao_image = res.url;

              let reader = new FileReader(); //实例化文件读取对象
              reader.readAsDataURL(pasteFile); //将文件读取为 DataURL,也就是base64编码
              reader.onload = (e) => {
                //文件读取成功完成时触发
                let dataURL = e.target.result; //获得文件读取成功后的DataURL,也就是base64编码
                that.images_base64 = dataURL;
                that.$refs.strategyCard6.changeImagesBase64(dataURL);
              };
            })
            .catch(function (err) {
              console.log(err);
            });
          // that.uploadImg(pasteFile);
          // pasteFile就是获取到的文件
        }
      }
    });
  },
  methods: {
    keyDown(e) {
      console.log("e", e);
      //Enter键发送消息
      if (e.ctrlKey && e.keyCode == 13) {
        //用户点击了alt+enter触发
        // 获取光标位置
        // let selection = window.getSelection()
        // let range = selection.getRangeAt(0)
        // range.setStart(range.endContainer, range.endOffset) // 设置开始插入位置
        // range.insertNode(<div>node</div>) // 插入元素，node节点
        // range.setEnd(range.endContainer, range.endOffset) // 设置结束位置
        // selection.selectAllChildren(input) //选择容器
        // selection.collapseToEnd() // 移动光标到最后
        console.log("key === 13hunaghang");
        this.editor.txt.append("<p data-we-empty-p><br></p>");
      } else {
        //用户点击了enter触发
        console.log("key === 发song");
        setTimeout(() => {
          this.privateChatSend();
        }, 10);
        // 执行一些逻辑方法
        // this.content += '<br/>';

        // if (e != undefined) {
        //   e.preventDefault(); // 阻止浏览器默认的敲击回车换行的方法
        // }
      }
    },
    onmessage(e) {
      //数据接收
      const redata = JSON.parse(e.data);
      var customerList = this.customerList;
      var circleList = this.circleList;
      var chatList = this.chatList;
      switch (redata.type) {
        //---------来访者打开聊天框
        case "user_join_teacher_group_accept":
          for (const [index, item] of customerList.entries()) {
            if (
              item.type == redata.user_info.type &&
              item.id == redata.user_info.id
            ) {
              customerList.splice(index, 1);
            }
          }
          customerList.unshift({
            id: redata.user_info.id,
            avatar: redata.user_info.avatar,
            type: redata.user_info.type,
            nickname: redata.user_info.nickname,
            time: redata.createtime_text,
            is_new: redata.is_new,
            is_new_message: redata.is_new_message,
          });
          break;
        //---------用户发送消息给讲师
        case "chat_teacher_accept":
          var currentTalkMember = this.currentTalkMember,
            messageList = this.messageList;
          if (redata.chat_type != 4) {
            for (const [index, item] of customerList.entries()) {
              if (
                item.type == redata.user_info.type &&
                item.id == redata.user_info.id
              ) {
                customerList.splice(index, 1);
              }
            }
            customerList.unshift({
              id: redata.user_info.id,
              avatar: redata.user_info.avatar,
              type: redata.user_info.type,
              nickname: redata.user_info.nickname,
              time: redata.createtime_text,
              is_new: redata.is_new,
              is_new_message: redata.is_new_message,
            });

            if (
              redata.chat_type == this.chat_type &&
              redata.user_id == currentTalkMember.id
            ) {
              messageList.push({
                id: redata.chat_id,
                user_id: redata.user_id,
                name: redata.user_info.nickname,
                avatar: redata.user_info.avatar,
                time: redata.createtime_text,
                createtime: redata.createtime,
                content: emojiReplace(redata.content),
                is_dummy: redata.user_info.is_dummy,
                direction: this.userInfo.id == redata.user_info.id ? 1 : 2,
                topic_id: redata.topic_id,
                topic_info: redata.topic_info,
                zan_num: redata.zan_num,
                message_type: redata.message_type,
                message_type_show: false,
                reply_info: {
                  id: redata.reply_id,
                  name: redata.reply_user_nickname,
                  content: emojiReplace(redata.reply_content),
                },
              });
              this.scoll1();
              this.audio.play();
              this.$notify({
                title: "提示",
                message: "您有一条新消息",
                duration: 5000,
              });
            }
          } else {
            for (const item of circleList) {
              if (item.uid == redata.teacher_id) {
                item.unread_num++;
                item.time = redata.createtime_text;
              }
            }

            if (
              redata.chat_type == this.chat_type &&
              redata.teacher_id == currentTalkMember.id
            ) {
              messageList.push({
                id: redata.chat_id,
                user_id: redata.user_id,
                name: redata.user_info.nickname,
                avatar: redata.user_info.avatar,
                time: redata.createtime_text,
                createtime: redata.createtime,
                content: emojiReplace(redata.content),
                is_dummy: redata.user_info.is_dummy,
                direction: this.userInfo.id == redata.user_info.id ? 1 : 2,
                topic_id: redata.topic_id,
                topic_info: redata.topic_info,
                zan_num: redata.zan_num,
                message_type: redata.message_type,
                message_type_show: false,
                reply_info: {
                  id: redata.reply_id,
                  name: redata.reply_user_nickname,
                  content: emojiReplace(redata.reply_content),
                },
              });
              this.scoll1();
              if (this.userInfo.id != redata.user_info.id) {
                this.audio.play();
                this.$notify({
                  title: "提示",
                  message: "您有一条新消息",
                  duration: 5000,
                });
              }
            }
          }

          break;
        //---------用户发送消息给讲师
        case "user_drop_line":
          if (this.customerStatus == 2) {
            for (const [index, item] of customerList.entries()) {
              if (item.id == redata.content.user_id) {
                customerList.splice(index, 1);
              }
            }
          }
          // console.log("会员断线", redata);
          break;
        // 直播间有用户发消息
        case "chat_room_accept":
          if (redata.room_id == this.userInfo.room_id) {
            chatList.push({
              id: redata.chat_id,
              name: redata.user_info.nickname,
              avatar: redata.user_info.avatar,
              content: emojiReplace(redata.content),
              createtime_text: redata.createtime_text,
              level: redata.user_info.level,
            });
            this.scoll2();
          }

          break;
        //直播间删除某条信息
        case "room_chat_delete":
          // console.log("删除之前", chatList);
          for (const [index, item] of chatList.entries()) {
            if (item.id == redata.content.id) {
              chatList.splice(index, 1);
            }
          }
          this.chatList = chatList;
          break;
        //---------websocket出现错误进行提示
        case "error":
          this.$message.error(redata.content);
          break;
      }
    },
    handleRemove() {
      console.log("@删除图片");
      this.form.zhibiao_image = "";
      this.images_base64 = "";
      this.$refs.strategyCard6.changeImagesBase64("");
    },
    handleAvatarSuccess(res, file) {
      this.form.zhibiao_image = res.data.fullurl;
      let reader = new FileReader(); //实例化文件读取对象
      reader.readAsDataURL(file.raw); //将文件读取为 DataURL,也就是base64编码
      reader.onload = (e) => {
        //文件读取成功完成时触发
        let dataURL = e.target.result; //获得文件读取成功后的DataURL,也就是base64编码
        this.images_base64 = dataURL;
        this.$refs.strategyCard6.changeImagesBase64(dataURL);
      };
    },
    // 发送开播提醒
    sendLiveTips() {
      this.$confirm("确定发送开播提醒吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.sendLiveTipsApi();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async sendLiveTipsApi() {
      const res = await Service.openLive();
    },
    // 关闭引用
    closeReply() {
      this.reply_info = {
        id: null,
        name: "",
        content: "",
      };
    },
    // 选择引用
    chooseReply(item) {
      this.reply_info = {
        id: item.id,
        name: item.name,
        content: item.content,
      };
    },
    // 创建音频
    createAudio() {
      const audio = new Audio(require("@/assets/music/qq.wav"));
      this.audio = audio;
      console.log("创建音频");
      // setTimeout(() => {
      //   this.audio.play();
      //   console.log("播放音频~");
      // }, 5000);
    },
    showMessageTypeShow(index) {
      this.messageList[index].message_type_show = true;
      return false;
    },
    showRealTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var days = new Array("日", "一", "二", "三", "四", "五", "六");
      var day = d.getDay();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "/" + month + "/" + date + "/";
      var cori_time = hour + ":" + min;
      this.date_time = cori_date + "" + cori_time;
      this.form.time = year + "-" + month + "-" + date;
      // this.currentTime = cori_time;
      // this.currentWeek = "星期" + days[day];
    },
    changeTime(e) {
      var arr = e.split("-");
      this.date_time = arr[0] + "年" + arr[1] + "月" + arr[2] + "日";
    },
    // 获取短线跟踪列表
    async getTechnologyCaseList(page) {
      const res = await Service.getTechnologyCaseList({
        page,
        limit: this.pageSize,
      });
      this.page = page;
      this.total = res.data.total;
      this.technologyCaseList = res.data.data;
    },
    async updataCelueId() {
      const res = await Service.getBaseInfo();
      this.form.dxcl_id = res.data.dxcl_id;
      this.loadingForm = true;
      setTimeout(() => {
        this.doCut();
      }, 1000);
    },
    // 获取基础信息
    async getBaseInfo1() {
      let that = this;
      const res = await Service.getBaseInfo();
      this.showRealTime();
      this.disclaimer = res.data.disclaimer;
      var teacherList = res.data.teacherList;
      for (const item of teacherList) {
        if (item.checked) {
          that.form.teacher_name = item.name;
          that.form.teacher_number = item.number;
          that.form.teacher_number2 = item.number2;
        }
      }
      that.teacherList = teacherList;

      this.qujian_arr = res.data.qujian_arr;
      this.zhibiao_arr = res.data.zhibiao_arr;
      // this.form.zhibiao = res.data.zhibiao_arr[0].name;
      this.laiyuan_arr = res.data.laiyuan_arr;
      // this.form.laiyuan = res.data.laiyuan_arr[0].name;
      this.form.dxcl_id = res.data.dxcl_id;
    },
    // 获取基础信息
    async getBaseInfo2() {
      const res = await ServiceB.BaseInfo();
      this.categoryList = res.data.categoryList;
    },
    changeCategory(e) {
      var categoryList = this.categoryList;
      for (const item of categoryList) {
        if (item.id == e) {
          this.category_name = item.name;
        }
      }
    },
    changeNumber(e) {
      var teacherList = this.teacherList;
      for (const item of teacherList) {
        if (item.number == e) {
          this.form.teacher_name = item.name;
          this.form.teacher_number2 = item.number2;
        }
      }
    },
    autoSend() {
      this.$store.state.ws.send(
        JSON.stringify({
          type: "chat_teacher_send",
          to_user_id: this.currentTalkMember.id,
          teacher_id:
            this.chat_type == 4 ? this.currentTalkMember.id : this.userInfo.id,
          content: this.disclaimer,
          chat_type: this.chat_type,
          dummy_id: this.dummy_id,
          topic_id: this.topic_id,
          config: {
            platform: "pushWeb",
          },
          message_type: "disclaimer",
        })
      );
      this.scoll1();
    },
    doCut() {
      let that = this;
      var form = that.form;
      html2canvas(document.querySelector("#capture"), {
        scale: 3,
      }).then((canvas) => {
        var imgUrl = canvas.toDataURL("image/png");
        that.imgUrl = imgUrl;
        console.log("imgUrl", imgUrl);
        // that.dialogVisible = true;
        setTimeout(() => {
          that.loadingForm = false;
        }, 3000);
        if (!form.time) {
          that.$message.error("请选择时间");
          return false;
        }
        if (!form.category_id) {
          that.$message.error("请选择品种");
          return false;
        }
        if (!form.contract) {
          that.$message.error("请输入合约");
          return false;
        }
        if (!form.qujian1 || !form.qujian2) {
          that.$message.error("标准支撑/压力有误请检查");
          return false;
        }
        if (!form.qujian_type) {
          that.$message.error("请选择方向");
          return false;
        }
        if (!form.qujian1 || !form.qujian2) {
          that.$message.error("目标位置有误请检查");
          return false;
        }
        if (!form.qujian1 || !form.qujian2) {
          that.$message.error("止损位置有误请检查");
          return false;
        }
        if (!form.teacher_name) {
          that.$message.error("请选择产品制作人");
          return false;
        }
        that.submit();
      });
    },
    async submit() {
      const res = await Service.uploadBase64({
        pic: this.imgUrl,
      });
      this.sendAppImage(res.data.url);
    },
    async sendAppImage(path) {
      const obj = { ...this.form };
      obj.image = path;
      obj.time = Math.round(new Date(obj.time + " 00:00:00") / 1000);
      const res = await Service.addShortStrategy(obj);
      this.$message.success(res.msg);
      // this.getTechnologyCaseList(1);
      this.resetFormData();
      // this.autoSend();
    },
    resetFormData() {
      this.imgUrl = "";
      this.images_base64 = "";
      this.date_time = "";
      this.category_name = null; //品种名称
      this.form = {
        time: "",
        dxcl_id: null,
        category_id: null,
        contract: "",
        qujian1: "",
        qujian2: "",
        qujian_type: "",
        mubiao1: "",
        mubiao2: "",
        zhisun1: "",
        zhisun2: "",
        cangwei: "",
        remark: "",
        image: "",
        teacher_name: "",
        teacher_number: "",
      }; // 开仓form
      this.getBaseInfo1();
      this.showRealTime();
    },
    // 设置直播间状态
    setLiveStatus(live_status) {
      let that = this;
      var text = live_status == 1 ? "开播" : "停播";
      that
        .$confirm("确认" + text + "吗?", "信息", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.changeCircleLiveStatus(live_status);
        })
        .catch(() => {});
    },
    async changeCircleLiveStatus(live_status) {
      const res = await ServiceChat.changeCircleLiveStatus({
        uid: this.currentTalkMember.id,
        live_status,
      });
      console.log("设置直播间播放状态", res);
      this.$message.success(res.msg);
      this.live_status = live_status;
    },
    // 复制文本
    copyText(e) {
      this.$copyText(e)
        .then((message) => {
          this.$message.success("复制完毕");
        })
        .catch((err) => {
          console.log("copy.err", err);
          this.$message.error("复制失败");
        });
    },
    // 多人群发弹窗关闭后执行
    handleClosed() {
      this.editor1.destroy();
      this.editor1 = null;
    },
    allSelect(e) {
      if (e === true) {
        this.$refs.multipleTable.toggleAllSelection();
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleCommand(e) {
      console.log("e", e);
      let that = this;
      if (e.type == "a") {
        that.showUserInfo(e.id);
      } else if (e.type == "b") {
        that
          .$confirm("确认禁言该用户吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            that.banConfirm(e.id);
          });
      }
    },
    beforeHandleCommand(e, id) {
      return {
        type: e,
        id,
      };
    },
    async showUserInfo(id) {
      const res = await ServiceChat.getCircleUserInfo({
        id,
        type: 1,
      });
      console.log("showUserInfo", res);
      this.information = res.data;
      this.dialogVisible = true;
    },
    // 获取话题列表
    async getTopicList() {
      const res = await ServiceC.topicList({
        is_paginate: 0,
      });
      this.topicList = res.data;
    },
    // 获取图片名称
    getFileName(file) {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var type = file.type.substr(
        file.type.lastIndexOf("/") + 1,
        file.type.length
      );
      return (
        "push/companyCircle/" +
        year +
        month +
        date +
        "/" +
        Math.round(new Date()) +
        "." +
        type
      );
    },
    // 创建富文本编辑器
    createEditor() {
      let that = this;
      var height = 160;
      const editor = new E("#editor");
      // 关闭粘贴样式的过滤
      editor.config.pasteFilterStyle = false;
      // 自定义处理粘贴的文本内容
      editor.config.pasteTextHandle = function (content) {
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
        if (that.content != content) {
          that.content = content;
          var str = content;
          str = str.replace(/<xml>[\s\S]*?<\/xml>/gi, "");
          str = str.replace(/<style>[\s\S]*?<\/style>/gi, "");
          str = str.replace(/<\/?[^>]*>/g, "");
          str = str.replace(/[ | ]*\n/g, "\n");
          str = str.replace(/&nbsp;/gi, "");
          console.log(str);
          return str;
        }
      };

      editor.config.uploadImgServer = true;
      let client = new OSS({
        // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
        region: "oss-cn-shanghai",
        // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
        accessKeyId: "LTAI5tRozUZ3WynSFfTzC72S",
        accessKeySecret: "HGfPDLd6zylQVVU7c9tkQT7uGKpRd7",
        bucket: "i-vce",
      });

      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        var name = that.getFileName(resultFiles[0]);
        client
          .put(name, resultFiles[0])
          .then(function (res) {
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.url);
          })
          .catch(function (err) {
            console.log(err);
          });
      };

      editor.config.height = height;
      editor.config.zIndex = 500;
      // 配置行高
      editor.config.lineHeights = [
        "0",
        "0.5",
        "1.0",
        "1.5",
        "2.0",
        "2.5",
        "3.0",
      ];
      // 配置菜单栏
      editor.config.menus = ["image"];
      // 设置不需要的菜单
      // editor.config.excludeMenus = [
      //   "link",
      //   "list",
      //   "todo",
      //   "justify",
      //   "quote",
      //   "video",
      //   "table",
      //   "code",
      //   "splitLine",
      // ];
      // 取消自动 focus
      // editor.config.focus = false;
      // 配置 onchange 回调函数
      editor.config.onchange = function (newHtml) {
        // var reult = newHtml.charAt(newHtml.length-1)
        that.textarea = newHtml;
      };
      console.log("@", editor.config);
      // 配置触发 onchange 的时间频率，默认为 200ms
      editor.config.onchangeTimeout = 500; // 修改为 500ms
      editor.create();
      editor.txt.html("");
      this.editor = editor;
    },
    // 获取圈子列表
    async getCircleList() {
      const res = await ServiceChat.getCircleList();
      var circleList = res.data.list;
      this.circleList = circleList;
      this.circleChat(circleList[0].uid, circleList[0].title, 0);
    },
    // 全员禁言&解禁
    async changeCircleChatStatus() {
      const res = await ServiceChat.changeCircleChatStatus({
        uid: this.currentTalkMember.id,
        status: this.circleStatus == 1 ? 0 : 1,
      });
      this.getCircleConfig();
      this.$message.success(res.msg);
    },
    // 修改圈子用户发言状态
    async changeCircleUserChatStatus(user_id, status, index) {
      const res = await ServiceChat.changeCircleUserChatStatus({
        uid: this.currentTalkMember.id,
        user_id,
        status: status == 1 ? 0 : 1,
      });
      this.$message.success(res.msg);
      this.circleUserList[index].status = status == 1 ? 0 : 1;
    },
    // 获取假人列表
    async getDummyList() {
      const res = await ServiceC.getUserList({
        is_paginate: 0,
      });
      // console.log("假人列表", res);
      this.dummyList = res.data;
    },
    // 显示圈子成员
    // showDrawer() {
    //   this.circleIsEnd = false;
    //   this.circleUserList = [];
    //   this.circleUserPage = 1;
    //   this.getCircleUserList(1);
    //   this.drawer = true;
    // },
    // 禁言
    async banConfirm(user_id) {
      const res = await ServiceChat.changeCircleUserChatStatus({
        uid: this.currentTalkMember.id,
        user_id,
        status: 0,
      });
      this.getCircleConfig();
      this.$message.success(res.msg);
    },
    // 删除发言
    async delConfirm(id) {
      const res = await ServiceChat.delCircleMessage({
        uid: this.currentTalkMember.id,
        id,
      });
      var messageList = this.messageList;
      for (const [index, item] of messageList.entries()) {
        if (item.id == id) {
          messageList.splice(index, 1);
        }
      }
      this.$message.success(res.msg);
    },
    async getUserData() {
      const res = await ServiceChat.getUserData();
      // console.log("获取弹窗统计信息", res);
      this.userData = res.data;
    },
    //会员列表检索
    search1() {
      this.page = 1;
      this.isEnd = false;
      this.customerList = [];
      this.getCustomerList1(1);
    },
    chatInit() {
      this.isEndMessage = false;
      this.privateEmojiStatus = false;
      this.textarea = "";
      this.editor.txt.html("");
      this.circleIsEnd = false;
      this.circleUserList = [];
      this.circleUserPage = 1;
    },
    // 圈子
    async circleChat(uid, title, index) {
      let that = this;
      this.circleList[index].unread_num = 0;
      this.currentTalkMember = {
        id: uid,
        type: 4,
        nickname: title,
      };
      this.chatInit();
      this.getCircleConfig();
      this.messageListLoading = true;
      this.chatBoxStatus = true;
      this.chatBoxRightStatus = false;
      this.chat_type = 4;
      this.circleUserList = []; // 圈子人员列表
      this.circleUserPage = 1;
      this.circleIsEnd = false;
      this.messageList = [];
      const res = await ServiceChat.getChatList({
        uid,
        // createtime: Math.round(new Date() / 1000),
        chat_type: 4,
      });
      // console.log("群聊信息", res);
      var list = res.data.list,
        messageList = this.messageList;
      for (const item of list) {
        messageList.unshift({
          id: item.id,
          user_id: item.user_id,
          name: item.user.nickname,
          avatar: item.user.avatar,
          time: item.createtime_text,
          createtime: item.createtime,
          content: emojiReplace(item.content),
          direction: item.user_id == this.userInfo.id ? 1 : 2,
          status: item.status,
          is_dummy: item.user.is_dummy,
          topic_id: item.topic_id,
          topic_info: item.topic_info,
          zan_num: item.zan_num,
          message_type: item.message_type,
          message_type_show: false,
          reply_info: {
            id: item.reply_id,
            name: item.reply_user_nickname,
            content: emojiReplace(item.reply_content),
          },
        });
      }
      this.messageListLoading = false;
      this.scoll1();
    },
    // 获取历史消息
    async getHistoryChatList() {
      if (this.isEndMessage) {
        return false;
      }
      var messageList = this.messageList;
      // console.log("createtime", messageList);
      // console.log("createtime", messageList[0].createtime);
      this.messageListLoading = true;
      const res = await ServiceChat.getChatList({
        uid: this.currentTalkMember.id,
        createtime: messageList[0].createtime,
        chat_type: this.chat_type,
      });
      var list = res.data.list;
      if (list.length) {
        for (const item of list) {
          messageList.unshift({
            id: item.id,
            user_id: item.user_id,
            name: item.user.nickname,
            avatar: item.user.avatar,
            time: item.createtime_text,
            createtime: item.createtime,
            content: emojiReplace(item.content),
            direction: item.user_id == this.userInfo.id ? 1 : 2,
            status: item.status,
            is_dummy: item.user.is_dummy,
            topic_id: item.topic_id,
            topic_info: item.topic_info,
            zan_num: item.zan_num,
            message_type: item.message_type,
            message_type_show: false,
            reply_info: {
              id: item.reply_id,
              name: item.reply_user_nickname,
              content: emojiReplace(item.reply_content),
            },
          });
        }
      } else {
        this.isEndMessage = true;
      }

      this.messageListLoading = false;
    },
    circleLoad() {
      if (!this.circleIsEnd) {
        this.getCircleUserList(this.circleUserPage);
      }
    },
    // 获取圈子人员列表
    async getCircleUserList(page) {
      this.circleUserListLoading = true;
      const res = await ServiceChat.getCircleUserList({
        uid: this.currentTalkMember.id,
        page,
        limit: this.limit,
        is_teacher: 1,
        is_online: this.is_online == "全部" ? 0 : 1,
      });
      const listNew = res.data.data;
      const circleUserList = this.circleUserList;
      this.circleUserTotal = res.data.total;
      if (listNew.length) {
        this.circleUserList = circleUserList.concat(listNew);
        this.circleUserPage = page + 1;
      } else {
        this.circleIsEnd = true;
      }
      this.circleUserListLoading = false;
      // console.log("圈子人员列表", res);
    },
    // 获取圈子配置
    async getCircleConfig() {
      const res = await ServiceChat.getCircleConfig({
        uid: this.currentTalkMember.id,
      });
      // console.log("获取圈子配置", res);
      this.circleStatus = res.data.status;
      this.userTotal = res.data.user_total;
      this.play_url = res.data.play_url;
      this.push_url = res.data.push_url;
      this.live_status = res.data.live_status;
    },
    // 多人群发
    async groupSend(isQuick) {
      var content = this.content,
        multipleSelection = this.multipleSelection,
        ids = [];

      if (isQuick != 1) {
        if (!multipleSelection.length) {
          this.$message.error("请至少选择一个发送对象");
          return false;
        }
        if (!content) {
          this.$message.error("发送消息不可为空");
          return false;
        }
      }

      for (const item of multipleSelection) {
        ids.push({
          id: item.id,
        });
      }

      const res = await ServiceChat.sendMassMessage({
        ids,
        content,
        is_quick: isQuick,
      });
      this.$message.success(res.msg);
      console.log("多人群发", res);
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.content = "";
      this.searchVal2 = "";
      this.editor1.txt.html("");
    },
    // 获取研究员信息
    async getTeacherInfo() {
      const res = await ServiceChat.getTeacherInfo();
      // console.log("获取研究员信息", res);
      this.userInfo = res.data.userinfo;

      this.getRoomChatList();
      this.$store.state.ws.send(
        JSON.stringify({
          type: "join_room_send",
          room_id: res.data.userinfo.room_id,
          config: {
            platform: "pcWeb",
          },
        })
      );
    },
    // 获取历史消息
    async getRoomChatList() {
      let that = this;
      const res = await ServiceIndex.roomChatList({
        room_id: that.userInfo.room_id,
        createtime: Math.round(new Date() / 1000),
      });
      console.log("getRoomChatList", res);
      var chatList = that.chatList;
      for (const item of res.data.list) {
        chatList.unshift({
          id: item.id,
          name: item.user.nickname,
          avatar: item.user.avatar,
          content: emojiReplace(item.content),
          createtime_text: item.createtime_text,
          level: item.user.level,
        });
      }
      that.chatList = chatList;
      that.scoll2();
    },
    load() {
      if (this.customerStatus == 1) {
        this.getCustomerList1(this.page);
      }
    },
    // 全部客户
    async getCustomerList1(page) {
      if (!this.isEnd) {
        this.customerListloading = true;
        const res = await ServiceChat.getCustomerList({
          page,
          search: this.searchVal1,
        });
        // console.log("全部客户", res);
        var list_new = res.data.data,
          customerList = this.customerList;
        if (list_new.length) {
          this.customerList = customerList.concat(list_new);
          this.page++;
        } else {
          this.isEnd = true;
        }
        this.customerListloading = false;
      }
    },
    // 在线客户
    async getCustomerList2() {
      this.customerListloading = true;
      const res = await ServiceChat.getOnLineCustomerList();
      console.log("在线客户", res);

      this.customerList = res.data;
      this.customerListloading = false;
    },
    // 获取当前选中的全部会员
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log("handleSelectionChange", val);
    },
    handleCurrentChange(val) {
      this.getMemberAll(val);
      console.log(`当前页: ${val}`);
    },
    handleCurrentChange1(val) {
      this.getTechnologyCaseList(val);
      console.log(`当前页: ${val}`);
    },
    // 私聊滚动
    scoll1() {
      $("#privateChat").animate(
        {
          scrollTop: "99999px",
        },
        1200
      );
    },
    // 直播间滚动
    scoll2() {
      $("#privateChat2").animate(
        {
          scrollTop: "9999px",
        },
        1200
      );
    },
    changeTouristNewsStatus(e) {
      this.customerStatus = e;
      this.customerList = [];
      this.page = 1;
      this.isEnd = false;
      if (e == 1) {
        this.getCustomerList1(1);
      } else {
        this.getCustomerList2();
      }
    },
    //私聊表情输入
    changeTextarea(e) {
      this.textarea += e;
      // this.editor.txt.html(this.editor.txt.html() + e);
      this.editor.txt.append(e);
    },
    //emoji选择列表展示状态更改
    changeEmojiStatus(e) {
      this.privateEmojiStatus = !this.privateEmojiStatus;
      this.emojiId = "private";
    },
    //选择私聊人员获取聊天记录
    async privateChat(type, id, nickname, index) {
      this.chatInit();
      this.messageListLoading = true;
      this.chatBoxStatus = true;
      this.chatBoxRightStatus = true;
      this.chat_type = 2;
      this.messageList = [];
      this.currentTalkMember = {
        id,
        type,
        nickname,
      };
      const res = await ServiceChat.getChatList({
        uid: id,
        createtime: Math.round(new Date() / 1000),
        chat_type: 2,
      });
      console.log("privateChat", res);
      var list = res.data.list,
        messageList = this.messageList;
      this.customerList[index].is_new_message = false;
      for (const item of list) {
        messageList.unshift({
          id: item.id,
          user_id: item.user_id,
          name: item.user.nickname,
          avatar: item.user.avatar,
          time: item.createtime_text,
          createtime: item.createtime,
          content: emojiReplace(item.content),
          direction: item.teacher_id == item.to_user_id ? 2 : 1,
          is_dummy: item.user.is_dummy,
          topic_id: item.topic_id,
          topic_info: item.topic_info,
          zan_num: item.zan_num,
          message_type: item.message_type,
          message_type_show: false,
          reply_info: {
            id: item.reply_id,
            name: item.reply_user_nickname,
            content: emojiReplace(item.reply_content),
          },
        });
      }
      this.messageListLoading = false;
      this.getUserInfo(type, id);
      this.scoll1();
    },
    //获取私聊人员相关信息
    async getUserInfo(type, id) {
      this.messageListLoading = true;
      const res = await ServiceChat.getUserInfo({
        type,
        id,
      });
      console.log("getUserInfo", res);
      this.messageListLoading = false;
      this.memberInfo = res.data;
    },
    //私聊发送
    privateChatSend() {
      if (!this.textarea) {
        this.$message.error("不能发送空消息");
        return false;
      }
      console.log("发送成功", this.textarea);
      var chatBoxRightStatus = this.chatBoxRightStatus;
      var dummy_id = this.dummy_id;
      var topic_id = this.topic_id;
      this.$store.state.ws.send(
        JSON.stringify({
          type: "chat_teacher_send",
          to_user_id: this.currentTalkMember.id,
          teacher_id:
            this.chat_type == 4 ? this.currentTalkMember.id : this.userInfo.id,
          content: this.textarea,
          chat_type: this.chat_type,
          dummy_id,
          topic_id,
          config: {
            platform: "pushWeb",
          },
          reply_id: this.reply_info.id,
        })
      );
      if (this.chat_type != 4) {
        this.messageList.push({
          id: null,
          user_id: null,
          name: this.userInfo.nickname,
          avatar: "",
          time: this.getSendTime(),
          createtime: Math.round(new Date() / 1000),
          content: emojiReplace(this.textarea),
          // is_dummy: dummy_id ? 1 : 0,
          direction: 1,
        });
      }
      this.textarea = "";
      this.editor.txt.html("");
      this.privateEmojiStatus = false;
      this.closeReply();
      var customerList = this.customerList;
      for (const [index, item] of customerList.entries()) {
        if (item.id == this.currentTalkMember.id) {
          item.is_new_message = false;
          customerList.splice(index, 1);
          customerList.unshift(item);
        }
      }
      this.scoll1();
    },
    getSendTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "-" + month + "-" + date;
      var cori_time = hour + ":" + min + ":" + sec;
      this.currentDate = cori_date;
      this.currentTime = cori_time;

      return cori_date + " " + cori_time;
    },
  },
};
</script>

<style scoped>
.side_left {
  width: 50%;
  height: 100vh;
  border-right: 1px solid #ebeced;
  box-sizing: border-box;
}

.private_chat {
  width: 30%;
  height: 100vh;
  border-right: 1px solid #ebeced;
}

.left_one {
  /* height: 580px; */
  height: calc(100% - 50px);
}

.left_two {
  /* height: calc(100% - 580px); */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #626a78;
  line-height: 20px;
  background: #f7f8fc;
  box-sizing: border-box;
  border-top: 1px solid #e7e9ef;
}

.left_two_div {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_drqf {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

.list_head {
  display: flex;
  background-color: #f8fcff;
  border-bottom: 1px solid #ebeced;
}

.list_head_cell {
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  padding: 11px 0;
  font-size: 16px;
  line-height: 22px;
}

.list_head_cell_active {
  position: relative;
  color: #4571e7;
}

.list_head_cell_active::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  border: 1px solid #0092fd;
}

.offline_un {
  margin-right: 20px !important;
}

.tourist_news_list {
  height: calc(100% - 90px);
}

.retrieval {
  height: 45px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeced;
}

.overflow_y {
  overflow-y: auto;
}

.overflow_y::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.overflow_y1::-webkit-scrollbar {
  display: block;
  width: 10px;
}

.overflow_y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.2);
}

.overflow_y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.tourist_news_cell {
  height: 66px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeced;
  cursor: pointer;
  position: relative;
}

.tourist_avatar_cell {
  width: 40px;
  height: 40px;
  margin-right: 6px;
}

.tourist_avatar_img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.tourist_identity {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  background-color: #4571e7;
  box-sizing: border-box;
  padding: 3px 4px;
  display: inline-flex;
  margin-right: 4px;
  flex-shrink: 0;
}

.tourist_time {
  color: #8691a3;
  font-size: 12px;
  line-height: 12px;
}

.h40 {
  height: 40px;
}

.icon_new {
  width: 18px;
  height: 18px;
}

.tourist {
  /* background-image: url("@/assets/img/tourist_bg.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.tourist_news_tips {
  position: absolute;
  right: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff3f30;
}

.cori_divider > div {
  font-size: 12px;
  font-weight: 400;
  color: #a3adbf;
  position: relative;
  text-align: center;
  margin: 10px 0;
}

.cori_divider > div::before {
  content: "";
  display: block;
  width: 30%;
  position: absolute;
  left: 45px;
  top: 10px;
  height: 1px;
  background: linear-gradient(90deg, #ffffff 0%, #e6e9f0 49%, #ffffff 100%);
}

.cori_divider > div::after {
  content: "";
  display: block;
  width: 30%;
  position: absolute;
  right: 45px;
  top: 10px;
  height: 1px;
  background: linear-gradient(90deg, #ffffff 0%, #e6e9f0 49%, #ffffff 100%);
}

.chat_list {
  background-color: #fff;
  box-sizing: border-box;
  padding: 10px;
  height: calc(100% - 174px);
}

.chat_btn {
  font-size: 12px;
  line-height: 12px;
  background-color: #5188f4;
  color: #fff;
  width: 60px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chat_input {
  width: calc(100% - 70px);
}

.private_chat_head {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
  background-color: #f8fcff;
  border-bottom: 1px solid #ebeced;
}

.private_chat_head > div > span:first-child {
  font-size: 16px;
  font-weight: 400;
  color: #3b5496;
  line-height: 22px;
  margin-right: 38px;
}

.private_chat_head > div > span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #94a3c3;
  line-height: 20px;
}

.estoppel {
  font-size: 12px;
  font-weight: 400;
  color: #959595;
  line-height: 17px;
  border-radius: 4px;
  border: 1px solid #d0d1d4;
  box-sizing: border-box;
  padding: 2px 6px;
}

.icon_shezhi {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.icon_operation {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.private_chat_body {
  display: flex;
  height: calc(100% - 54px);
}

.circle_users {
  display: flex;
  flex-wrap: wrap;
}

.circle_user_cell {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #6a6a6a;
  line-height: 17px;
  margin-right: 16px;
  margin-bottom: 14px;
  width: 48px;
}

.circle_user_cell .avatar {
  width: 46px;
  height: 46px;
  border-radius: 8px;
  display: flex;
}

.circle_user_cell .icon_jinyan {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.private_chat_left {
  width: calc(100% - 250px);
}

.max_width {
  width: 100% !important;
}

.private_chat_right {
  width: 250px;
  height: 100%;
  box-sizing: border-box;
  border-left: 1px solid #ebeced;
  background-color: #f7f8fc;
}

.private_chat_list {
  box-sizing: border-box;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.quote_body {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 26px;
  background-color: #f2f2f2;
  font-size: 14px;
  font-weight: 400;
  color: #989898;
  display: flex;
  align-items: center;
}

.quote_body .content {
  padding-left: 13px;
  box-sizing: border-box;
  border-left: 1px solid #989898;
}

.quote_body .content >>> img {
  max-width: 150px !important;
}

.icon_close3 {
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  right: 18px;
}

.private_chat_bottom {
  width: 100%;
  height: 340px;
  background-color: #f7f8fa;
}

.private_chat_bottom_more {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 24px 0 16px;
}

.private_chat_bottom_more_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.switch_identity {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #7c7f86;
  cursor: pointer;
  line-height: 20px;
}

.switch_identity img {
  width: 22px;
  height: 22px;
}

.switch_identity_avatar {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  margin-right: 6px;
}

.private_chat_input_body {
  height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 0 10px;
}

.private_chat_input_body textarea {
  width: 100%;
  box-sizing: border-box;
  height: calc(100% - 40px);
  border: 1px solid #cccccc;
  resize: none;
  outline: none;
  padding: 10px;
}

.private_chat_right_title {
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeced;
  box-sizing: border-box;
  padding: 0 10px;
  line-height: 14px;
  font-size: 14px;
}

.private_chat_right_title img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.border-b {
  border-bottom: 1px solid #ebeced;
}

.customer_info {
  box-sizing: border-box;
  padding: 10px;
  height: calc(100% - 210px);
}

.customer_cell {
  display: flex;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 14px;
  color: #94a3c3;
}

.customer_cell > div:first-child {
  width: 60px;
}

.customer_cell > div:last-child {
  width: calc(100% - 60px);
}

.cori_divider_small > div {
  color: #5288f5;
}

.cori_divider_small > div::before {
  width: 30%;
  left: 15px;
}

.cori_divider_small > div::after {
  width: 30%;
  right: 15px;
}

.cori_chat_box_list_cell {
  display: flex;
}

.cori_chat_box_list_cell_avatar {
  width: 32px;
  height: 32px;
  margin-right: 6px;
  border-radius: 8px;
}

.cori_chat_box_list_cell_info {
  width: calc(100% - 38px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mr6 {
  margin-right: 6px;
}

.cori_chat_box_list_cell_content {
  margin: 6px 0 20px;
  font-size: 14px;
  max-width: 65%;
  background-color: #f7f8fc;
  box-sizing: border-box;
  padding: 6px 10px;
  border-radius: 5px;
}

.cori_chat_box_list_cell_content .reply_content {
  color: #7e7e7e;
  border-bottom: 1px solid rgba(211, 211, 211, 0.6);
  margin-bottom: 10px;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.cori_chat_box_list_cell_content .reply_content_name {
  margin-bottom: 10px;
}

.cori_chat_box_list_cell_content .reply_content_content {
}

.cori_chat_box_list_cell_content .reply_content_content >>> img {
  max-width: 150px !important;
  /* max-height: 80px; */
}

.cori_chat_box_list_cell_content .more_handles {
  position: absolute;
  display: none;
  bottom: 5px;
  width: 40px;
}

.cori_chat_box_list_cell_content .more_handles .icon_message {
  width: 16px;
  height: 16px;
  display: block;
  cursor: pointer;
}

.cori_chat_box_list_cell_content:hover .more_handles {
  display: flex;
}

.cori_chat_box_list_cell_content >>> div {
  word-break: break-all;
}

.disclaimer_content {
  max-height: 125px;
  overflow: hidden;
}

.disclaimer_viewing_all {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  border-radius: 14px;
  box-sizing: border-box;
  padding: 3px 14px;
  border: 1px solid #fff;
  margin: 15px auto 5px;
  display: inline-flex;
}

.topic_about {
  width: 270px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 20px;
}

.topic_about_cell {
  background: #e6ebf8;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 3px 11px;
  color: #4571e7;
  line-height: 18px;
}

.icon_topic {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.icon_zan {
  width: 17px;
  height: 16px;
  margin-left: 7px;
}

.color_huiyuan {
  background-color: #4571e7;
}

.color_youke {
  background-color: #4571e7;
}

.color_jiangshi {
  background-color: #ff3f30;
}

.color_guanli {
  background-color: #ffbc01;
}

.color_kefu {
  background-color: #ffbc01;
}

.avatar {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.group_send {
  width: calc(100% - 420px);
}

.h260 {
  height: 260px;
}

.is_end {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px 0;
  font-size: 12px;
  color: #ccc;
}

.dummy_text {
  background-color: #ff7220 !important;
  color: #fff !important;
}

.loading {
  width: 18px;
  height: 18px;
  margin: 0 10px;
}

.firm_offer_name {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  width: 100%;
  text-align: center;
  border-radius: 0 0 8px 8px;
}

.is_firm_offer {
  position: absolute;
  background-color: #4571e7;
  color: #fff;
  transform: rotate(45deg);
  top: -6px;
  right: -14px;
  text-align: center;
  width: 40px;
  height: 24px;
  line-height: 32px;
}

.is_firm_offer div {
  font-size: 12px;
}

.online_status1,
.online_status0 {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
}

.online_status1 {
  color: #00ffae !important;
  font-weight: bold;
}
.online_status0 {
}

.staff_status_online,
.staff_status_offline {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 3px 0 5px;
}

.staff_status_online {
  background: #00ffae;
  box-shadow: 0px 1px 4px rgba(11, 182, 120, 0.7);
}

.staff_status_offline {
  filter: grayscale(100%);
  filter: gray;
}

.biankuang {
  border: 1px solid #ff0000;
  border-radius: 8px;
}

.el-dropdown {
  height: 32px !important;
}

/deep/.el-dialog {
  --el-dialog-box-shadow: 0 1px 3px rgb(140 140 140 / 15%) !important;
  --el-border-radius-small: 0px !important;
}

/deep/.el-dialog__header {
  background-color: #5188f4;
  /* border-radius: 10px 10px 0 0; */
}

/deep/.el-dialog__title {
  color: #fff !important;
}

.user_info_dialog .customer_cell {
  color: #000 !important;
}

.video_url_cell {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #a5aab4;
  margin-right: 20px;
  cursor: pointer;
}

.plate_push_btn {
  width: 70px;
  height: 30px;
  background-color: #e2e8f3;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #a3adbf;
  border-radius: 3px;
}

.plate_push_btn_active {
  background-color: #5389f5;
  color: #fff;
}

.icon_push_video {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.icon_push_speak {
  width: 9px;
  height: 16px;
  margin-right: 5px;
}

.icon_push_stop {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.h500 {
  height: 450px;
}

.form_body {
  height: calc(100% - 40px);
  overflow-y: auto;
}

.w30 {
  height: 100%;
}

.plate_push_btn {
  width: 70px;
  height: 30px;
  background-color: #e2e8f3;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #a3adbf;
  border-radius: 3px;
}

.plate_push_btn_active {
  background-color: #5389f5;
  color: #fff;
}

.icon_push_video {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.icon_push_speak {
  width: 9px;
  height: 16px;
  margin-right: 5px;
}

.icon_push_stop {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  /* padding: 18px 0; */
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-right: 25px;
}

.back {
  line-height: 16px;
  color: #5389f5;
  margin-left: 30px;
  cursor: pointer;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name1 {
  position: relative;
  line-height: 16px;
  padding-left: 15px;
  display: flex;
  align-items: center;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.plate_title_1 {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 13px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.plate_title_1 > img {
  width: 24px;
  height: 24px;
  margin: 0 10px;
}

.preview_table {
  width: 450px;
  height: 100vh;
  /* width: 275px; */
  flex-shrink: 0;
  background-color: #f8fcff;
  box-sizing: border-box;
  border-right: 1px solid #ebeced;
}

.form {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.mt32 {
  margin-top: 30px;
}

/deep/.el-input-number--large {
  width: 112px;
}

/deep/.el-divider--horizontal {
  width: 30px;
  margin: 0 8px;
}

.beizhu {
  width: calc(100% - 351px);
  min-width: 222px;
}

.preview_body {
  width: 100%;
  height: calc(100% - 51px);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  padding: 20px 0;
}

.preview_body::-webkit-scrollbar {
  display: block;
  width: 4px;
  /*height: 6px;*/
}

.preview_body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.6);
}

.preview_body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.preview {
  display: flex;
  font-size: 14px;
  margin: 5px 0;
}

.preview_left {
  width: 90px;
  color: #a3adbf;
  text-align: right;
}

.preview_right {
  width: calc(100% - 100px);
  margin-left: 10px;
  color: #303656;
  box-sizing: border-box;
  padding-right: 5px;
}

.publicity_map {
  box-sizing: border-box;
  position: absolute;
  width: 375px;
  height: 812px;
  background-size: cover;
  background-image: url(../../assets/img/duanxiancelue_bg.png);
  background-position: center;
  background-repeat: no-repeat;
}

.no_data {
  color: #b7b7b7 !important;
}

.w_input_px {
  width: 100px !important;
}

.live_room_body {
  /* width: 300px; */
  width: 100%;
  flex-shrink: 0;
  height: 100%;
}

.live_message_lists {
  height: calc(100% - 51px);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 20px;
}

.live_message_cell {
  box-sizing: border-box;
  padding: 12px 0;
  border-bottom: 1px solid #ebeef5;
}

.live_message_cell .name {
  font-weight: normal;
  font-size: 14px;
  color: #131313;
  max-width: 105px;
}

.live_message_cell .time {
  font-weight: normal;
  font-size: 12px;
  color: #707070;
}

.live_message_cell .content {
  font-weight: normal;
  font-size: 14px;
  color: #707070;
  margin-top: 6px;
  word-break: break-all;
}

.avatar-uploader_div {
  width: 178px;
  height: 178px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
