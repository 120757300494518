<template>
  <div>
    <zhengxin v-if="userInfo.company_id == 1"></zhengxin>
    <xinhu v-else-if="userInfo.company_id == 4"></xinhu>
    <donghai
      v-else-if="userInfo.company_id == 6 || userInfo.company_id == 5"
    ></donghai>
    <defaultCompany v-else></defaultCompany>
  </div>
</template>

<script>
import * as ServiceChat from "@/api/chat";
import zhengxin from "@/components/company/zhengxin.vue";
import xinhu from "@/components/company/xinhu.vue";
import donghai from "@/components/company/donghai.vue";
import defaultCompany from "@/components/company/defaultCompany.vue";
export default {
  components: {
    zhengxin,
    xinhu,
    donghai,
    defaultCompany,
  },
  data() {
    return {
      userInfo: {}, // 1正信4新湖5火锅测试6东海
    };
  },
  created() {},
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getTeacherInfo();
  },
  methods: {
    onmessage(e) {},
    // 获取研究员信息
    async getTeacherInfo() {
      const res = await ServiceChat.getTeacherInfo();
      // console.log("获取研究员信息", res);
      this.userInfo = res.data.userinfo;

      this.$store.state.ws.send(
        JSON.stringify({
          type: "join_room_send",
          room_id: res.data.userinfo.room_id,
          config: {
            platform: "pcWeb",
          },
        })
      );
    },
  },
};
</script>

<style>
/deep/.el-popper {
  max-width: max-content !important;
}
</style>
<style scoped></style>
